<template>
  <master-layout>

    <ion-card>
      <ion-card-content>
        <h1>Platform demo</h1>
        <p>
          <b>Platform</b>: {{ platform }}
        </p>
        <p>
          <b>Device</b>: {{ deviceDemo }}
        </p>
        <h1>
          Sendig to:
        </h1>
        <p>
          <b>{{ sendTo }}</b>
        </p>
      </ion-card-content>
    </ion-card>
    <div class="bottomSpacer"></div>

  </master-layout>
</template>

<script>
import { Device } from '@capacitor/device'
import { isPlatform } from '@ionic/vue'
import {
  IonCard, IonCardContent
} from '@ionic/vue'

export default {
  components: {
    IonCard, IonCardContent
  },
  data () {
    return {
      platform: null,
      device: null,
      sendTo: null,
      deviceDemo: null
    }
  },
  ionViewWillEnter () {
    this.getPlatform()
    this.getDeviceDemo()
  },
  methods: {
    async getPlatform () {
      // check  platform
      if (isPlatform('mobile') && !isPlatform('tablet')) {
        this.platform = 'mobile & not tablet'
        this.getDevice()
      } else {
        this.platform = 'not mobile or mobile+tablet'
        this.sendTo = 'PlatformError page'
      }
    },
    async getDevice () {
      this.device = await Device.getInfo()
      // ios
      if(this.device.operatingSystem == 'ios') {
        let iosOk = false
        let supportedIosVersions = ['13','14','15','16','17']
        for (let i in supportedIosVersions) {
          if (this.device.osVersion.match(supportedIosVersions[i])) {
            iosOk = true
          }
        }
        if (iosOk) {
          this.sendTo = 'IOS 13 or higher => App Store'
        } else {
          this.sendTo = 'IOS 12 or lower => PWA'
        }
      // android
      } else if (this.device.operatingSystem == 'android') {
        let androidOk = false
        let supportedAndroidVersions = ['5','6','7','8','9','10','11','12','13','14']
        for (let i in supportedAndroidVersions) {
          if (this.device.osVersion.match(supportedAndroidVersions[i])) {
            androidOk = true
          }
        }
        if (androidOk) {
          this.sendTo = 'Android 5 or higher => Google Play'
        } else {
          this.sendTo = 'Android 4 or lower => PWA'
        }
      // other than ios and android
      } else {
        this.sendTo = 'Other platform than IOS or Android => PWA'
      }
    },
    async getDeviceDemo () {
      this.deviceDemo = await Device.getInfo()
    },
    sendToApp () {
      window.location = `Bortúra://app/udvozoljuk`
    }
  }
}
</script>

<style scoped>
ion-button{
  margin: 10px 0;
}
h1{
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: 33px;
}
p{
  text-align: center;
}
.illustration{
  display: block;
  width: 90%;
  margin: 40px auto 20px auto;
}
</style>
